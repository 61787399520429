import { services } from '../../Data/services';
import styles from './services.module.css';
import '../container.css';
import Card from './ServiceCard/Card';

const Services =() =>{
    return (
        <div className="container" id="services">
            <div className={styles.head}>
                <h3>Our Services</h3>
                <h4>The main purpose of this group is to provide all the necessary frontend and backend support to entreprenuers, small business and content creators under one roof. Here you find a complete, complex and professional group of services that will allow you to create a good online presence and make your business a strong, effective and efficient one.</h4>
            </div>
            <div className={styles.services}>
                {
                    services.map(data => {
                        return(<Card data={data}/>)
                    })
                }
            </div>
        </div>
    );
}
export default Services;