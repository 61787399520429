export const faqData = [
    {
        ques: 'What is Talinto Information Services?',
        ans: 
        <p>
            Talinto Information Service Pvt. Ltd. is a startup that helps other startups, small businesses and content creators to grow from incubation level to acceleration. The company provides all the necessary pin-to-plane services that are required to grow a startup from scratch. This includes business development, entrepreneurial leadership, go to market and growth strategy, web development and designing, MVP and prototype, pitch deck, working on business models, financial management, fund raising, production, designing, advertising and marketing and many others.
            <br/>
            The main purpose of our startup is to provide all the necessary frontend and backend support to people who are starting with startup, small business and content creation under one roof. This will help them to focus on their key skill and leave the rest of the things on us. 
            <br/>
            This will also help business organisations to remove the hurdle of hiring freelancers again and again as they are outsourcing their whole operation to one company.
        </p>
    },
    {
        ques: "Is all the work done by the in-house team?",
        ans: "Yes, all the work done by the in-house team."
    },
    {
        ques: "When should I expect to see the results?",
        ans: "With the second month of associating with us, you can expect to see the result."
    },
    {
        ques: "What if my company/organization is out of state or country?",
        ans: "We have made a whole setup age and have an in-house team. So, it won't effect, if the company is out of state or country. We will do frequent meetings from the client, both online and offline and will ensure the operation of work."
    },
    {
        ques: "Can you help me with online as well as offline sales?",
        ans: "Yes. For exclusive details give our experts a call at +91-92123 07242 or +91-87509 84049"
    },
    {
        ques: "Why is Talinto the best for marketing and development?",
        ans: "We have working professionals from the industry and young minds having proper insights and know who'll help entrepreneurs to grow"
    },
]