import styles from './Contact.module.css';
import Button from '../common/Button/Button';
import {useState} from 'react';
import swal from 'sweetalert';
import { API_URL } from '../../Constants/URL';

const ContactForm =(props)=>{
    
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [industry, setIndustry] = useState('');
    const [description, setDescription] = useState('');

    const nameChangeHandler =(e)=>
    {
        setFullName(e.target.value);
    }
    const emailChangeHandler =(e)=>
    {
        setEmail(e.target.value);
    }
    const phoneChangeHandler =(e)=>
    {
        setPhone(e.target.value);
    }
    const countryChangeHandler =(e)=>
    {
        setCountry(e.target.value);
    }
    const stateChangeHandler =(e)=>
    {
        setState(e.target.value);
    }
    const cityChangeHandler =(e)=>
    {
        setCity(e.target.value);
    }
    const industryChangeHandler =(e)=>
    {
        setIndustry(e.target.value);
    }
    const descriptionChangeHandler =(e)=>
    {
        setDescription(e.target.value);
    }

    const onSubmit =()=>
    {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "name": fullName,
            "email": email,
            "city": city,
            "country": country,
            "message": description,
            "number": phone,
            "state": state,
            "industryType": industry
          });
          
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };
          
          fetch(`${API_URL}/contact`, requestOptions)
            .then(response => {
                
                if(response.status===200)
                {
                    swal({
                        title: "Thank You",
                        text: "We'll contact you shortly.",
                        icon: "success",
                        button: "OK",
                      });
                      props.closeModal();
                }
                else
                {
                    swal({
                        title: "Oops!",
                        text: "An error occured, please fill the information corretly.",
                        icon: "error",
                        button: "OK",
                      });
                }
                
                return response.json()
            })
            .catch(error => {
                console.log('error', error)
                swal({
                    title: "Oops!",
                    text: "An error occured, please fill the information corretly.",
                    icon: "error",
                    button: "OK",
                  });
            });
    }
    
    return(
        <>
            <div className={styles.overlay} onClick={props.closeModal}></div>
            <div className={styles.modalContainer} id='modal'>
                <form className={styles.form}>
                    <div className={styles.inputGroup}>
                        <label className={styles.label}>Full Name</label>
                        <input type='text' className={styles.input} value={fullName} onChange={(e)=>nameChangeHandler(e)}/>
                    </div>
                    <div className={styles.inputGroup}>
                        <label className={styles.label}>Email</label>
                        <input type='email' className={styles.input} value={email} onChange={(e)=>emailChangeHandler(e)}/>
                    </div>
                    <div className={styles.inputGroup}>
                        <label className={styles.label}>Phone No.</label>
                        <input type='text' className={styles.input} value={phone} onChange={(e)=>phoneChangeHandler(e)}/>
                    </div>
                    <div className={styles.inputGroupHorizontal}>
                        <div className={styles.inputGroup} style={{marginRight: '10px'}}>
                            <label className={styles.label}>Country</label>
                            <input type='text' className={styles.input} value={country} onChange={(e)=>countryChangeHandler(e)}/>
                        </div>
                        <div className={styles.inputGroup} style={{marginLeft: '10px'}}>
                            <label className={styles.label}>State</label>
                            <input type='text' className={styles.input} value={state} onChange={(e)=>stateChangeHandler(e)}/>
                        </div>
                    </div>
                    <div className={styles.inputGroupHorizontal}>
                        <div className={styles.inputGroup} style={{marginRight: '10px'}}>
                            <label className={styles.label}>City</label>
                            <input type='text' className={styles.input} value={city} onChange={(e)=>cityChangeHandler(e)}/>
                        </div>
                        <div className={styles.inputGroup} style={{marginLeft: '10px'}}>
                            <label className={styles.label}>Industry Type</label>
                            <input type='text' className={styles.input} value={industry} onChange={(e)=>industryChangeHandler(e)} required/>
                        </div>
                    </div>
                    <div className={styles.inputGroup}>
                        <label className={styles.label}>Description</label>
                        <textarea type='text' className={styles.input} rows='10' value={description} onChange={(e)=>descriptionChangeHandler(e)}/>
                    </div>
                    <div className={styles.buttonsGroup}>
                        <Button text='Cancel' bgcolor='#FFF' textcolor='black' onClick={props.closeModal}/>
                        <Button text='Submit' onClick={onSubmit}/>
                    </div>
                </form>
            </div>
        </>
    )
}

export default ContactForm;