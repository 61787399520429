import land from '../../Assets/landing.png';
import styles from './landing.module.css';
import '../container.css';
import Button from '../common/Button/Button';

const Landing = () =>{
    return (
        <div className="container">
            <div className={styles.landing}>
                <div className={styles.text}>
                    <h2>One Stop Shop for Startups, Small Businesses & Content Creators</h2>
                    <h2 className={styles.mobileText}>One Stop Shop for Startups & Small Businesses</h2>
                    <h4>If you have an idea then you are just at the right place.</h4>
                    <Button className={styles.Button} text='Get Started' shadow={true} fontSize='28px' link='#about'/>
                </div>
                <div className={styles.pic}>
                    <img src={land} className={styles.land}></img>
                </div>
            </div>
        </div>
    );
}
export default Landing;