import about from '../../Assets/about.png';
import styles from './about.module.css';
import '../container.css';
import Button from '../common/Button/Button';
import ContactForm from '../ContactModal/ContactForm';
import { useState } from 'react';

const About= () =>{
    
    const [modalOpen, setModalOpen] = useState(false);
    const closeModal =()=>
    {
        setModalOpen(false);
        document.body.style.overflow='unset';
        
    }
    const openModal =() =>
    {
        setModalOpen(true);
        document.body.style.overflow='hidden';

    }
    
    return (
        <>
        <div className={styles.about} id="about">
            <div className="container">
                <div className={styles.head}>
                    <h2>Who are we?</h2>
                </div>
                <div className={styles.flex}>
                    <div className={styles.img}>
                        <img src={about}></img>
                    </div>
                    <div className={styles.text}>
                        <h4>Talinto is a platform to assist people with their business ideas and creations, which will help them grow, and open doors to the world.</h4>
                        <h4>A successful establishment requires a team of experts to back them up. Our team has the depth of talent and experience to develop and implement your strategic vision. We can help you start up, grow, and transition your organization through the challenges you face in rapidly changing markets.</h4>
                    </div>
                </div>
                <div className={styles.cta}>
                    <h3>Get Started Today</h3>
                    <h4> Make better decisions for your business with us</h4>
                    <Button text='Contact Us' shadow={true} onClick={openModal}/>
                </div>
            </div>
        </div>
        {
            modalOpen?<ContactForm closeModal={closeModal}/>:null
        }
        </>
    );
}
export default About;