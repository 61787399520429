import About from "./Components/About/About";
import Faqs from "./Components/FAQs/Faqs";
import Footer from "./Components/Footer/Footer";
import Landing from "./Components/Landing/Landing";
import Navbar from "./Components/Navbar/Navbar";
import Privacy from "./Components/Privacy/Privacy";
import Services from "./Components/Services/Services";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';



function App() {
  return (
   <>
     <Router>
      <Navbar/>
       <Switch>
         <Route exact path='/'>
            <Landing />
            <About />
            <Services/>
            <Faqs/>
         </Route>
         <Route exact path='/privacy'>
            <Privacy/>
         </Route>
       </Switch>
      <Footer />
     </Router>
   </>
  );
}

export default App;
