import finance from '../Assets/finance.png';
import consult from '../Assets/consulting.png';
import market from '../Assets/marketing.png';
import webdev from '../Assets/webdev.png';
import retail from '../Assets/retail.png';
import art from '../Assets/art.png';
import business from '../Assets/business.png';
import content from '../Assets/content.png';
import leader from '../Assets/leader.png';
import software from '../Assets/software.png';

export const services=[
    {
        img: business,
        title: 'Business Management and Development',
        description: 'Our Services are delivered by experienced trainers who are motivated, passionate about what they do and have proven expertise in their fields. We would be providing management and development services to all the startups/small businesses starting from scratch.'
    },
    {
        img: leader,
        title: 'Entrepreneurial Leadership',
        description: 'Startups and small businesses can get overwhelmed by the intricate planning and execution needed to get the business off the ground or to get it through dramatic growth. We can help you get your business off the ground or through dramatic growth by leveraging our expertise in entrepreneurial leadership.'
    },
    {
        img: finance,
        title: 'Financial Management',
        description: 'Financial management is about three things: understanding the financial statements of a company, knowing what you can do with them, and knowing what to do with them. We make finance simple and easy to understand.'
    },
    {
        img: consult,
        title: 'Consulting and Advisory',
        description: 'Our team is ready to help you with your latest consulting and advisory needs. From company formation to project management, including assisting with loan applications and negotiation, we do it all.'
    },
    {
        img: webdev,
        title: 'Web development/designing',
        description: 'We offer Web development/designing services to startups and small businesses. We can take your business to a whole new level by helping you engage a larger audience with a stunning site and developing a mobile-friendly web presence.'
    },
    {
        img: software,
        title: 'Software and Application Development',
        description: 'We work directly with startups and small businesses to assist them in building a custom solution that gets them closer to their goals. Our team is made up of skilled developers who would work with our clients at every stage in the process from requirements gathering to final deployment.'
    },
    {
        img: art,
        title: 'Art Direction',
        description: 'Art direction is a process where you curate, design and produce content in a way that makes your customers want to buy your products and services. We bring your business objectives and your creative vision together into a cohesive, consistent message that can be expressed across multiple platforms.'
    },
    {
        img: content,
        title: 'Content direction',
        description: "Are you looking for help in creating content for your startup or small business?  Is this making you anxious, frustrated and pushed to the point where you've given up?  Not sure how to begin?  Not sure how much to do? We make creating online content easy."
    },
    {
        img: market,
        title: 'Advertising and Marketing',
        description: 'Here at the company our goal is to provide a valuable service to anyone looking for great ads and marketing services. We can help you reinvigorate your current online presence or create a new one from scratch.'
    },
    {
        img: retail,
        title: 'Manufacturing and Retail',
        description: 'Manufacturing and retailing can be confusing and expensive. We’ll work with you to find the best solution for your business goals and timeline, and we’ll be available for ongoing support as needed.'
    }
];