import styles from './privacy.module.css';
import '../container.css';
import { useHistory } from 'react-router';

const Privacy=() =>{
    
    const history=useHistory();
    return (
        <div className="container">
            <div className={styles.privacy}>
                <h2>Privacy Policy</h2>
            </div>
            <div className={styles.body}>
                <h3>We are committed to protecting your privacy</h3>
                <h5>Your personal credentials are safe with us at all times. We dedicate ourselves to provide our clients with a smooth user experience while using this website, and anything you enter or save here is in safe hands and no third party can have access to your data. We may offer aggregate information to third parties from time to time, such as the number of people who visit our website or fill out a registration form, but we will not use any information that might be used to identify those people. During the time spent on this website we collect a few details about you and there’s no obligation on you for access or not accessing this website. </h5>
            </div>
            <div className={styles.body}>
                <h3>Information Collected</h3>
                <h5>We collect any information that you give us about you; your name, address, telephone number, and email address, together with data about your use of the website.</h5>
            </div>
            <div className={styles.body}>
                <h3>Information Use</h3>
                <h5>We primarily utilise the information we collect to complete the task for which you came to the website. Unauthorized access to this information is prevented by taking all necessary steps. If you want to get information about your account details, you may have to submit additional forms of identification because of this security.</h5>
            </div>
            <div className={styles.body}>
                <h3>Cookies</h3>
                <h5>Small files called "cookies" are stored in your browser and allow a website to recognise your account. To improve your experience, our website utilises this feature. You can set your computer to refuse to accept cookies, but this may affect some website functionality.</h5>
            </div>
            <div className={styles.body}>
                <h3>Changes to this Policy</h3>
                <h5>We might make modifications to our privacy policy from time-to-time, which will also get updated on the website at the same time for you to access. If at any time in the future we wish to disclose information collected on this website to any third party, it would only be with your knowledge and consent.</h5>
            </div>
            <div className={styles.body}>
                <h3>Contacting Us</h3>
                <h5>If you have any queries about our Privacy Policy, or if you want to know what information we have collected about you, please email us at abc@gmail.com. You can also correct any factual errors in that information or require us to remove your details from any list under our control.</h5>
            </div>
        </div>
    );
}
export default Privacy;