import styles from './Card.module.css';

const Card =({data}) =>
{
    return(
        <div className={styles.cardContainer}>
            <img src={data.img} className={styles.img}/>
            <h2 className={styles.title}>
                {data.title}
            </h2>
            <p className={styles.description}>
                {data.description}
            </p>
        </div>
    );
}

export default Card;