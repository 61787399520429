import '../container.css';
import logo from '../../Assets/TALINTO_LOGO.png';
import styles from './navbar.module.css';
import Button from '../common/Button/Button';
import {useState} from 'react';
import ContactForm from '../ContactModal/ContactForm';
import { useHistory } from 'react-router';

const Navbar=()=>{
    
    const [modalOpen, setModalOpen] = useState(false);
    const [navOpen, setOpenNav] = useState(false);
    const closeModal =()=>
    {
        setModalOpen(false);
        document.body.style.overflow='unset';
        
    }
    const openModal =() =>
    {
        setModalOpen(true);
        document.body.style.overflow='hidden';

    }

    const openNav = () =>
    {
        setOpenNav(!navOpen);
    }
    
    const history=useHistory();
    return (
        <>
            <div className="container" id="home">
                <div className={styles.nav}>
                    <img src={logo} className={styles.logo} alt='talinto' onClick={()=>{history.push('/')}}/>
                    <div className={styles.toggler}><i className="fas fa-bars fa-3x" onClick={openNav} /></div>
                    <div className={styles.navButtonsClose} id='navButtons'>
                        <Button text='About Us' bgcolor='#FFF' textcolor='black' link="#about" onClick={()=>{history.push('/#about')}}/>
                        <Button text='Our Services' bgcolor='#FFF' textcolor='black' link="#services" onClick={()=>{history.push('/#services')}}/>
                        <Button text='Contact' bgcolor='#FFF' textcolor='black' link="#contact" onClick={()=>{history.push('/#contact')}}/>
                        <Button text='Contact Us' shadow={true} onClick={openModal}/>
                    </div>
                   
                </div>
            </div>
            {
                navOpen?<div className={styles.navButtonsOpen}>
                <div className={styles.tabs}>
                <Button text='About Us' textcolor='white' bgcolor='transparent' link="#about" onClick={()=>{history.push('/#about')}}/>
                <Button text='Our Services' textcolor='white' bgcolor='transparent' link="#services" onClick={()=>{history.push('/#services')}}/>
                <Button text='Contact' textcolor='white' bgcolor='transparent' link="#contact" onClick={()=>{history.push('/#contact')}}/> </div>
                <Button text='Contact Us' shadow={true} onClick={openModal}/>
                </div>:null
            }
            {
                modalOpen?<ContactForm closeModal={closeModal}/>:null
            }
        </>
    );
}
export default Navbar;