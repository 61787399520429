import '../container.css';
import styles from './faqs.module.css';
import { faqData } from '../../Data/faqs';
import FaqElement from './FaqElement';

const Faqs =() =>
{
    return(

        <div className={styles.faqConatiner}>
            <div className={styles.head}>
                <h3>FAQs</h3>
            </div>
            <div className={styles.list}>
                {
                    faqData.map(data => {
                        return(<FaqElement data={data}/>);
                    })
                }
            </div>
        </div>
    );
}

export default Faqs;