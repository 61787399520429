import styles from './faqs.module.css';
import {useState} from 'react';

const FaqElement = ({data}) =>
{
    const [showAns, setShowAns] = useState(false);
    const down= "fas fa-chevron-circle-down";
    const up= "fas fa-chevron-circle-up";
    
    return(
        <div className={styles.faq}>
            <div className={styles.ques} onClick={()=>setShowAns(!showAns)}>
                <h3 >
                    {data.ques}
                </h3>
                <i class={showAns?up:down}></i>
            </div>
            {    
                showAns?
                <p>
                    {data.ans}
                </p>
                :null
            }
        </div>
    )
}

export default FaqElement;