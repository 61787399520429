import styles from './footer.module.css';
import '../container.css';
import {Redirect, useHistory } from 'react-router';
import { useState } from 'react';
import ContactForm from '../ContactModal/ContactForm';

const Footer = () =>{
    
    const history=useHistory()
    const showPrivacy =() =>
    {
       document.documentElement.scrollTop=20;
        history.push('/privacy');
    }

    const [modalOpen, setModalOpen] = useState(false);
    const closeModal =()=>
    {
        setModalOpen(false);
        document.body.style.overflow='unset';
        
    }
    const openModal =() =>
    {
        setModalOpen(true);
        document.body.style.overflow='hidden';

    }

    return (
        <>
        <div className={styles.footer}>
            <div className="container">
                <div className={styles.flex}>
                    <div className={styles.about}>
                        <h3>About Us</h3>
                        <h4>Talinto is a platform to assist people with their business ideas and creations, which will help them grow, and open doors to the world.</h4>
                        <h4>Talinto is based on the idea of making it easy for people to interact with each other and exchange ideas and thoughts that can be implemented into real life. It is more than just a business.</h4>
                    </div>
                    <div className={styles.links}>
                        <h3>Links</h3>
                        <a onClick={()=>{history.push('/')}} href="#home">Home</a>
                        <a onClick={()=>{history.push('/#about')}} href="#about">About</a>
                        <a onClick={()=>{history.push('/#services')}} href="#services">Services</a>
                        <a onClick={openModal}>Contact Us</a>
                    </div>
                    <div className={styles.legal}>
                        <h3>Legal</h3>
                        <a onClick={showPrivacy}>Privacy Policy</a>
                        <a>Terms and Conditions</a>
                    </div>
                    <div className={styles.contact}>
                        <div className={styles.address}>
                            <h3>Address</h3>
                            <h4>House No.1, Shop No.1,</h4>
                            <h4>Ground Floor, J & K Extension,</h4>
                            <h4>Laxmi Nagar- New Delhi 110092</h4>
                        </div>
                        <div className={styles.contactus} id="contact">
                            <h3>Contact Us</h3>
                            <div>
                                <i class="fas fa-envelope"></i> <h4>talinto.services@gmail.com</h4>
                            </div>
                            <div>
                                <i class="fas fa-phone"></i> <h4>+91-9212307242</h4>
                            </div>
                            <div className={styles.icons}>
                                <a href="https://twitter.com/_talinto_?t=IMTFbkgGB82w8_j4ViUrzg&s=09" target="_blank"><i class="fab fa-twitter"></i></a>
                                <a href="https://instagram.com/_talinto_?utm_medium=copy_link" target="_blank"><i class="fab fa-instagram"></i></a>
                                <a><i class="fab fa-linkedin"></i></a>
                                <a href="https://www.facebook.com/Talinto-102837852087683/" target="_blank"><i class="fab fa-facebook"></i></a>
                                <i class="fas fa-linkedin"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.copyright}>
                    <h6>© 2021,Talinto Information Services Pvt. Ltd. All Rights Reserved.</h6>
                </div>
            </div>
        </div>
        {
            modalOpen?<ContactForm closeModal={closeModal}/>:null
        }
        </>
    );
}
export default Footer;