import { Colors } from "../../../Constants/Colors"

const Button = ({
    text,
    link,
    bgcolor = Colors.purple,
    textcolor = '#FFF',
    fontSize = '24px',
    shadow = false,
    shadowColor = Colors.purpleTransparent,
    ...props
}) =>
{
    const styles = 
    {
        background:  
        {
            backgroundColor: bgcolor,
            padding: '10px 18px',
            margin: '12px',
            marginTop: '8px',
            textAlign: 'center',
            borderRadius: '8px',
            border: 'none',
            display: 'inline-block',
            boxShadow: shadow ? `0 0 20px ${shadowColor}` : 'none',
            cursor: 'pointer'
        },
        text:
        {
            color: textcolor,
            fontSize: fontSize,
        },
        link: 
        {
            textDecoration: 'none'
        }
    }

    return(
        <div style={styles.background} onClick={props.onClick}>
            <a href={link} style={styles.link}>
                <span style={styles.text}>{text}</span>
            </a>
        </div>
    )
    
}

export default Button;
